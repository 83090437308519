import React from "react";

import { ExternalLink, ExternalLinkTypes, Layout, SEO } from "../components";
import { Colors } from "../components/constants/colors";

const IndexPage = () => (
  <Layout>
    <SEO title="ITcians | Full Stack Development for start-ups and small businesses" />

    <div style={{ textAlign: "center" }}>
      <h2 style={{ color: Colors.Teal, fontWeight: 200 }}>
        Our website is under construction
      </h2>

      <p>
        Reach us at{" "}
        <ExternalLink url={ExternalLinkTypes.GeneralPhoneLink}>
          {ExternalLinkTypes.GeneralPhoneText}
        </ExternalLink>{" "}
        or{" "}
        <ExternalLink url={ExternalLinkTypes.GeneralEmailLink}>
          {ExternalLinkTypes.GeneralEmailText}
        </ExternalLink>
        or chat with us.
      </p>
    </div>
  </Layout>
);

export default IndexPage;
